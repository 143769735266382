import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Buy() {
  return (
    <div className='h-screen '>
        <Navbar/>
        <div className='bg-black text-black text-left justify-items-center p-20'>
            <div className='bg-white  justify-center p-2 rounded-lg'>
                <p className='justify-items-center  text-center underline font-bold'>MAKE PAYMENT</p>
                <div className='flex gap-1  justify-between'>
                    <div className='flex h-32'>
                    <img src="image/visa.png" alt='' className=' object-cover'/>                  
                    <img src="image/paypal.png" alt='' className=' object-cover'/>
                
                     </div>        
                   
                </div>
                <div  className='grid gap-2 '>
                    <div>
                        <p>Card Number</p>
                        <input
                        className='border w-full h-10 p-2'/>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className=''>
                        <p>Ex.date</p>
                        <input
                        className='border  h-10'/>
                    </div>
                    <div className=''>
                        <p>Cvv</p>
                        <input
                        className='border w-12 pl-2 h-10'/>
                    </div>
                </div>

                <div className='justify-center justify-items-center w-full pt-5 pb-5'>
                    <button className='bg-orange-600 w-full rounded-lg h-10'>comfirm payment</button>
                </div>
            </div>
        </div>
      <Footer/>
    </div>
  )
}

export default Buy;